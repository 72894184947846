import React from 'react';

export const Contractor = () => {
  return (
    <div className='container'>
        <h2>Условия договоров с контрагентами</h2>
        <h2>ООО «МедГуру»</h2>

        <h3><strong>1.</strong>Общие положения</h3>
        <p><strong>1.1.</strong> Настоящие Условия (далее – «Условия») регулируют отношения, связанные с оказанием услуг, выполнением работ, поставкой товаров, передачей имущественных прав в адрес ООО «МедГуру» (далее – «Заказчик») лицом, применяющим специальный налоговый режим «Налог на профессиональный доход» или упрощенную систему налогообложения (далее – «Исполнитель»).</p>
        <p><strong>1.2.</strong> Настоящие Условия являются неотъемлемой частью договора, заключаемого между Заказчиком и Исполнителем (далее – «Договор»), и не являются публичной офертой. Полным и безоговорочным акцептом настоящих Условий является согласование Заказчиком выставленного Исполнителем Счета-оферты, содержащего ссылку на них. Счет-оферта и настоящие Условия образуют единое и полное соглашение Сторон по поводу оказания услуг, выполнения работ, поставки товаров, передачи имущественных прав, указанных в Счете-оферте. Выставляя Счет-оферту, Исполнитель подтверждает, что ознакомлен, полностью и безоговорочно принимает настоящие Условия.</p>
        <p><strong>1.3.</strong> Настоящие Условия могут быть в любое время изменены Заказчиком в одностороннем внесудебном порядке без какого-либо специального уведомления. Действующая редакция Условий всегда находится на сайте Заказчика по ссылке http://www.medguru.studio/contractor. Новая редакция Условий вступает в силу с даты ее размещения на сайте и применяется к отношениям, возникшим после ее опубликования. Заказчик рекомендует регулярно проверять Условия на предмет их изменения. Продолжение сотрудничества с Заказчиком после внесения изменений в Условия означает полное и безоговорочное согласие и принятие таких изменений Исполнителем.</p>
        <p><strong>1.4.</strong> Договор не требует скрепления печатями и/или подписания Заказчиком и Исполнителем в силу ст. 434 Гражданского кодекса РФ.</p>
        <p><strong>1.5.</strong> Оказываемые услуги, выполняемые работы, поставляемые товары, передаваемые имущественные права, указываются в Счете-оферте.</p>
        <p><strong>1.6.</strong> Сроки исполнения обязательств указываются в Счете-оферте или согласуются Сторонами по электронной почте и/или в мессенджерах.</p>

        <h3><strong>2.</strong>Расчеты</h3>
        <p><strong>2.1.</strong> Стоимость услуг/работ/товаров/имущественных прав указывается в Счете-оферте.</p>
        <p><strong>2.2.</strong> Заказчик оплачивает услуги/работы/товары/имущественные права в течение 10 (десяти) рабочих дней с даты их приемки, если иное не согласовано Сторонами в Счете-оферте. </p>
        <p><strong>2.3.</strong> Оплата производится в рублях перечислением на расчетный счет Исполнителя, указанный в Счете-оферте.</p>
        <p><strong>2.4.</strong> Исполнитель является плательщиком налога на профессиональный доход (НПД) или применяет упрощенную систему налогообложения (указывается в Счете-оферте). Исчисление и уплата НПД и/или иных налогов и взносов производится Исполнителем самостоятельно. Стоимость услуг/работ/товаров/имущественных прав НДС не облагается (п. 9 ст. 2 Федерального закона от 27.11.2018 № 422-ФЗ "О проведении эксперимента по установлению специального налогового режима "Налог на профессиональный доход", гл. 26.2 Налогового кодекса РФ).</p>
        <p><strong>2.5.</strong> По факту каждого платежа Исполнитель – плательщик НПД обязан не позднее дня, следующего за днем получения такого платежа, выставлять Заказчику в установленном законодательством порядке чек путем его направления контактному лицу со стороны Заказчика по электронной почте. Чек должен содержать все установленные законодательством реквизиты, а также ИНН Заказчика и наименование оказанных услуг в соответствии с Договором. В случае невыдачи (несвоевременной выдачи) Заказчику чека или выдачи его с нарушениями Исполнитель возмещает Заказчику все убытки, возникшие у Заказчика, в связи с этим.</p>
        <p><strong>2.6.</strong> В случае утраты Исполнителем по любым причинам права на применение специального налогового режима "Налог на профессиональный доход", упрощенной системы налогообложения или изменений законодательства, обязывающих уплачивать любые налоги, взносы и/или сборы со стоимости Услуг по настоящему Договору (НДС, НДФЛ и проч.), Стороны договорились считать все суммы, указанные в Счете-оферте, включающими в себя все применимые налоги, взносы и/или сборы по ставкам согласно действующему законодательству.</p>
        <p><strong>2.7.</strong> В случае прекращения у Исполнителя по любым причинам права на применение специального налогового режима "Налог на профессиональный доход", упрощенной системы налогообложения Исполнитель должен незамедлительно письменно уведомить об этом Заказчика, в противном случае Исполнитель возмещает Заказчику все убытки, возникшие у Заказчика, в связи с этим, в том числе суммы доначисленных налогов и взносов.</p>

        <h3><strong>3.</strong>Интеллектуальная собственность</h3>
        <p><strong>3.1.</strong> В случае, если Исполнителем при исполнении его обязательств будут созданы любые охраняемые результаты интеллектуальной деятельности (программы для ЭВМ, из части, видеозаписи, фотографии, изображения, тексты и т.д.), вне зависимости от того, предусматривалось ли создание таких результатов при заключении Договора, выставлении Счета-оферты или в последующем, все права на такие результаты интеллектуальной деятельности, включая исключительное право, принадлежат Заказчику с момента создания таких результатов и не передаются Исполнителю, если иное не согласовано Сторонами в Счете-оферте.</p>
        <p><strong>3.2.</strong> В случае, если предметом Счета-оферты является передача имущественного права в отношении результата интеллектуальной деятельности, то, если в Счете-оферте прямо не указано иное, Заказчику передается исключительное право на такой результат интеллектуальной деятельности.</p>
        <p><strong>3.3.</strong> В случае, если предметом Счета-оферты является лицензия на использование результата интеллектуальной деятельности, то, если иное прямо не указано в Счете-оферте:
            <ul>
                <li>лицензия является простой (неисключительной);</li>
                <li>срок действия лицензии – весь срок действия исключительного права на результат интеллектуальной деятельности;</li>
                <li>территория использования результата интеллектуальной деятельности – весь мир;</li>
                <li>способы использования – все способы, предусмотренные ст. 1270 Гражданского кодекса РФ.</li>
            </ul>
        </p>

        <h3><strong>4.</strong>Ответственность Сторон</h3>
        <p><strong>4.1.</strong> Стороны несут ответственность в соответствии с действующим законодательством Российской Федерации, за исключением случаев, прямо предусмотренных настоящими Условиями или Счетом-офертой.</p>
        <p><strong>4.2.</strong> Стороны освобождаются от ответственности за невыполнение или ненадлежащее выполнение обязательств в случае наступления обстоятельств непреодолимой силы, под которыми подразумеваются чрезвычайные события, не существовавшие во время акцепта настоящих Условий, выставления Счета-оферты, возникшие помимо воли Сторон, наступлению и действию которых они не могли препятствовать, а также иные события и обстоятельства, которые компетентный орган (Торгово-промышленная палата) либо, в случае спора, арбитражный суд признают обстоятельствами непреодолимой силы. К обстоятельствам непреодолимой силы относятся, в том числе, но не ограничиваясь нижеперечисленным: запретительные действия органов власти (санкции, эмбарго, карантин, режим повышенной готовности, военного или чрезвычайного положения), гражданские волнения, эпидемии, пандемии, военные действия, специальные или антитеррористические операции, блокада, землетрясения, наводнения, пожары или другие стихийные бедствия.</p>
        <p><strong>4.3.</strong> На время действия обстоятельств непреодолимой силы, освобождающих от ответственности, обязательства Сторон приостанавливаются, санкции за неисполнение договорных обязательств не применяются, а срок исполнения договорных обязательств продлевается на срок, соответствующий периоду действия наступившего обстоятельства непреодолимой силы и разумному сроку для устранения его последствий.</p>
        <p><strong>4.4.</strong> Сторона, для которой создалась невозможность исполнения обязательств, предусмотренных настоящими Условиями, обязана в разумный срок известить другую Сторону о наступлении вышеуказанных обстоятельств, но в любом случае не позднее 5 (пяти) рабочих дней с момента, когда станут доступны средства связи. Неуведомление или несвоевременное уведомление о возникновении обстоятельств непреодолимой силы лишает такую Сторону права на освобождение от ответственности.</p>
        <p><strong>4.5.</strong> В случае, если действие обстоятельств непреодолимой силы продолжается непрерывно в течение более 6 (шести) месяцев подряд, любая из Сторон вправе расторгнуть Договор в одностороннем внесудебном порядке, письменно уведомив другую Сторону. При этом Договор прекращается через 5 (пять) рабочих дней после получения другой Стороной такого уведомления.</p>

        <h3><strong>5.</strong>Налоговая оговорка</h3>
        <p><strong>5.1.</strong> Настоящим Исполнитель (далее – «Контрагент») в порядке ст. 431.2 Гражданского кодекса РФ заверяет Заказчика, что при заключении и исполнении Договора не преследует цель неуплаты (неполной уплаты) налогов, обязательства по Договору исполняются и будут исполняться лицом, являющимся Стороной Договора и (или) лицом, которому обязательство по исполнению Договора передано в соответствии с настоящими Условиями или законом. </p>
        <p><strong>5.2.</strong> Контрагент в порядке ст. 431.2 Гражданского кодекса РФ заверяет, что:</p>
        <p><strong>5.2.1</strong> Контрагент уплачивает все налоги и сборы в соответствии с действующим законодательством, а также ведет и своевременно подает в налоговые и иные государственные органы налоговую, статистическую и иную отчетность в соответствии с законодательством;</p>
        <p><strong>5.2.2</strong> Все операции Контрагента по реализации товаров, работ, услуг, имущественных прав, указанных в настоящих Условиях, полностью отражаются в бухгалтерской, налоговой, статистической и любой иной отчетности Контрагента (в зависимости от того, что применимо);</p>
        <p><strong>5.2.3</strong> У Контрагента имеются в достаточном количестве производственные мощности, квалифицированные кадры, имущество, необходимые лицензии, разрешения, свидетельства о членстве в саморегулируемой организации и т.п., обеспечивающие возможность реального выполнения настоящих Условий;</p>
        <p><strong>5.2.4</strong> Контрагент обязуется в случае возложения исполнения Договора на третье лицо по первому требованию в течение 3 (трех) рабочих дней документально подтвердить соответствующие обязательства между ним и таким третьим лицом;</p>
        <p><strong>5.2.5</strong> Контрагент не осуществляет и не будет осуществлять в ходе исполнения Договора уменьшение налоговой базы и (или) суммы подлежащего уплате налога в результате искажения сведений о фактах хозяйственной жизни (совокупности таких фактов), об объектах налогообложения, подлежащих отражению в налоговой и бухгалтерской отчетности, любыми способами;</p>
        <p><strong>5.2.6</strong> У Контрагента отсутствуют какие-либо ограничения полномочий лиц, подписывающих Договор в соответствии с законодательством и внутренними документами Контрагента.</p>
        <p><strong>5.3.</strong> Все предусмотренные настоящими Условиями заверения об обстоятельствах Контрагента имеют существенное значение. При недостоверности данных заверений об обстоятельствах, а равно при ненадлежащем исполнении Контрагентом требований действующего законодательства РФ, в том числе в части своевременного декларирования и уплаты налогов, предоставления достоверной налоговой отчетности, совершения иных предусмотренных налоговым законодательством обязанностей, Контрагент обязан в полном объеме возместить ООО «МедГуру» причиненные убытки в полном объеме, в том числе, но не ограничиваясь:</p>
        <p><strong>5.3.1</strong> возникшие в результате отказа в возмещении причитающихся ему сумм налогов, доначисления налоговыми органами налогов, начисления пеней, наложения штрафов, по причинам, связанным с Контрагентом, включая, но не ограничиваясь: </p>
        <p><strong>5.3.1.1</strong> суммы налогов, пеней и штрафов, подлежащих уплате в бюджетную систему РФ на основании вступивших в силу решений налоговых органов;</p>
        <p><strong>5.3.1.2</strong> суммы НДС, не полученных на основании вступивших в силу решений налоговых органов об отказе в возмещении НДС из бюджета;</p>
        <p><strong>5.3.1.3</strong> суммы налогов, пеней и штрафов по требованиям, предъявленных налоговым органом в судебном порядке, при условии наличия вступившего в законную силу судебного акта, на основании которого возлагается обязанность по уплате соответствующих сумм. </p>
        <p><strong>5.3.2</strong> в связи с предъявлением третьим лицом, требований о возмещении потерь и убытков в виде уплаченных им налогов (пеней, штрафов), доначисленных из-за отказа в применении налоговых вычетов по НДС или из-за исключения стоимости приобретенных товаров (работ, услуг, имущественных прав) из расходов по налогу на прибыль, по причинам, связанным с Контрагентом.</p>
        <p><strong>5.4.</strong> Убытки подлежат возмещению Контрагентом в течение 5 (пяти) рабочих дней со дня получения Контрагентом соответствующей письменной претензии.</p>

        <h3><strong>6.</strong>Конфиденциальность</h3>
        <p><strong>6.1.</strong> Исполнитель обязуется обеспечивать неразглашение конфиденциальной информации, полученной от Заказчика. Для целей настоящих Условий Заказчик является Раскрывающей стороной, а Исполнитель – Принимающей Конфиденциальную информацию стороной.</p>
        <p><strong>6.2.</strong> Конфиденциальная информация – любая предоставленная Раскрывающей стороной информация в объективной (устной, письменной, электронной) форме и на любом материальном носителе, в отношении которой Раскрывающей стороной сделано заявление или отметка о её конфиденциальности в настоящих Условиях и/или иным явным образом, в том числе, но не ограничиваясь, информация, относящаяся к служебной и/или коммерческой тайне, т.е. имеющая действительную или потенциальную коммерческую ценность в силу неизвестности ее третьим лицам, к которой у третьих лиц нет свободного доступа на законном основании.
        К Конфиденциальной информации в любом случае относятся:</p>
        <p><strong>6.2.1</strong> база данных партнеров и клиентов Раскрывающей стороны;</p>
        <p><strong>6.2.2</strong> персональные данные;</p>
        <p><strong>6.2.3</strong> информация о бизнес-процессах Раскрывающей стороны;</p>
        <p><strong>6.2.4</strong> отчетность Раскрывающей стороны, к которой нет доступа у третьих лиц на законном основании;</p>
        <p><strong>6.2.5</strong> информация о продуктах Раскрывающей стороны, включая исходный код программ для ЭВМ;</p>
        <p><strong>6.2.6</strong> пароли и логины для доступа к информационным ресурсам Раскрывающей стороны, предоставленные Принимающей стороне.</p>
        <p><strong>6.3.</strong> Конфиденциальная информация также может включать (при наличии отметки о конфиденциальности при передаче такой информации): условия сделок и соглашений Сторон; производственную, финансово-экономическую, юридическую информацию, информацию о результатах интеллектуальной деятельности; информацию, относящуюся к прошлым, текущим или будущим исследованиям, идеям, ноу-хау, технологиям и методам производства; доклинические и клинические исследования; финансовые результаты и прогнозы; расходы и цены (потенциальные или фактические); информацию о клиентах и поставщиках; кадровую информацию; информацию о консультантах и агентах; технические, финансовые и деловые стратегии; стратегии в области маркетинга и продвижения продукции; информацию о программном, аппаратном и техническом обеспечении; методологию и процессы, образцы продукции и их спецификации.</p>
        <p><strong>6.4.</strong> Информация не будет считаться Конфиденциальной, и Принимающая сторона не будет иметь никаких обязательств в отношении данной информации, если эта информация на момент передачи: а) не обозначена как конфиденциальная в настоящих Условиях и/или иным явным образом; б) является общедоступной (используется в печати, иных средствах массовой информации, опубликована в сети Интернет); в) была известна Принимающей стороне на момент раскрытия такой информации Раскрывающей стороной или независимо получена ею от третьего лица без нарушения настоящих Условий, либо г) разрешена к распространению с письменного согласия Раскрывающей стороны.</p>
        <p><strong>6.5.</strong> Все сведения, относящиеся к Конфиденциальной информации в соответствии с настоящими Условиями и ставшие известными Принимающей стороне в результате сотрудничества с Раскрывающей стороной, являются конфиденциальными, и Принимающая сторона берет на себя обязательства не разглашать их без предварительного письменного согласия Раскрывающей стороны, выраженного в явном виде, за исключением случаев, перечисленных в п. 8.4 Договора.</p>
        <p><strong>6.6.</strong> Принимающая сторона обязуется защищать Конфиденциальную информацию Раскрывающей стороны уместным и разумным образом, обеспечивая безопасность и уровень защиты не меньший, чем для своей собственной Конфиденциальной информации такого же уровня секретности.</p>
        <p><strong>6.7.</strong> Принимающая сторона обязуется использовать Конфиденциальную информацию строго для целей сотрудничества с другой Стороной, и данная Конфиденциальная информация не может быть использована в каких-либо других целях.</p>
        <p><strong>6.8.</strong> Принимающая сторона обязуется во время и в течение 5 (пяти) лет после завершения сотрудничества с раскрывающей Стороной не использовать Конфиденциальную информацию, полученную в результате сотрудничества, в целях конкуренции с Раскрывающей стороной, и известить Раскрывающую сторону о любом известном ей конфликте интересов, имеющим место в данной связи.</p>
        <p><strong>6.9.</strong> Принимающая сторона обязуется уничтожить (в максимально доступной с точки зрения технических средств мере) и/или вернуть все копии Конфиденциальной информации в течение 30 (тридцати) дней после соответствующего запроса Раскрывающей стороны и в случае необходимости подписать акт о такой передаче/уничтожении или предоставить необходимые доказательства.</p>

        <h3><strong>7.</strong>Порядок разрешения споров</h3>
        <p><strong>7.1.</strong> Претензионный порядок урегулирования споров, разногласий и требований, возникающих из настоящих Условий или в связи с ними, в том числе касающиеся их вступления в силу, акцепта, изменения, исполнения, нарушения, прекращения или действительности, является обязательным. Претензией признается мотивированное письменное требование одной Стороны, адресованное другой Стороне, совершить какие-либо действия либо воздержаться от их совершения. Срок ответа на претензию составляет 10 (десять) рабочих дней с момента, когда претензия была получена либо в соответствии с применимым законодательством считается полученной Стороной, которой она адресована. В случае полного или частичного отказа в удовлетворении претензии или неполучения направившей претензию Стороной в срок ответа на претензию, претензионный порядок урегулирования споров считается соблюденным, и направившая претензию Сторона вправе обратиться в суд.</p>
        <p><strong>7.2.</strong> Все споры, разногласия или требования, возникающие из настоящих Условий или в связи с ними, в том числе касающиеся их вступления в силу, акцепта, изменения, исполнения, нарушения, прекращения или действительности, подлежат рассмотрению судом по месту нахождения Заказчика.</p>

        <h3><strong>8.</strong>Заключительные положения</h3>
        <p><strong>8.1.</strong> Во всем остальном, что не урегулировано настоящими Условиями, Стороны руководствуются законодательством Российской Федерации (применимое право), без учета коллизионных норм.</p>
        <p><strong>8.2.</strong> Ни одна из Сторон не вправе передавать права и/или обязанности по данному Договору третьей Стороне без предварительного письменного согласия на это другой Стороны, в том числе уступать права денежного требования по Договору.</p>
        <p><strong>8.3.</strong> Заказчик вправе в любое время расторгнуть Договор в одностороннем внесудебном порядке и без возмещения Исполнителю каких-либо убытков или затрат, уведомив Исполнителя по электронной почте, чем за 10 (десять) рабочих дней до даты расторжения, с проведением всех расчетов по Договору.</p>
        <p><strong>8.4.</strong> Все гарантии и заверения об обстоятельствах, содержащиеся в настоящих Условиях, имеют существенное значение для Сторон.</p>

        <h3>Реквизиты ООО «МедГуру»</h3>
        <ul>
            <li><strong>ИНН:</strong> 7716860313</li>
            <li><strong>КПП:</strong> 773401001</li>
            <li><strong>ОГРН:</strong> 1177746556130</li>
            <li><strong>ОКВЭД (основной):</strong> 62.02</li>
            <li><strong>Юридический и почтовый адрес:</strong> 123060, Москва, ул. Маршала Рыбалко, д. 2, к. 6, помещ. I комната 5 офис 722 </li>
            <li><strong>E-mail:</strong> info@medguru.studio</li>
            <li><strong>Телефон:</strong> +7 (495) 445 4510</li>
            <li><strong>Банковские реквизиты:</strong></li>
            <ul>
                <li>р/сч: 40702810102860004288</li>
                <li>Банк: АО «АЛЬФА-БАНК»</li>
                <li>к/сч: 30101810200000000593</li>
                <li>БИК: 044525593</li>
            </ul>
        </ul>
    </div>
  )
}
