import { React, useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Main from "./pages/Main";
import Omni from "./pages/Omni";
import CRM from "./pages/CRM";
import Production from "./pages/Production";
import Contacts from "./pages/Contacts";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Stack from "./components/OragenPage/Stack";
import { Contractor } from "./components/Contractor";
import { DynamicPage } from "./components/DynamicPage";
import { dynamicRoutes } from "./Page";

export default function App () {
  const [currentPath, setCurrentPath] = useState('/');
  const location = useLocation();

  
  const scrollToElement = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location.pathname]);

  return (
    <div className="App">
      <Header/>
      <Routes>
          <Route exact path="/" element={<Main scrollToElement={scrollToElement}/>} />
          <Route path="/omnichannel" element={<Omni />} />
          <Route path="/crm" element={<CRM />} />
          <Route path="/production" element={<Production />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/stack" element={<Stack />} />
          <Route path="/contractor" element={<Contractor />} />

          {/* Генерация динамических маршрутов */}
          {Object.entries(dynamicRoutes).map(([parentPath, subRoutes]) =>
              subRoutes.map(({ id, title, img, link }) => (
                <Route
                  key={`${parentPath}/${id}`}
                  path={`/${parentPath}/${id}`}
                  element={<DynamicPage title={title} img={img} link={(link)} />}
                />
              ))
            )}
      </Routes>  
      <Footer currentPath={currentPath} scrollToElement={scrollToElement}/>
    </div>
  );
}

